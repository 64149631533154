import { Document, Font, Page } from "@react-pdf/renderer";
import { ReactNode } from "react";

import RegularPoppins from "../../../../assets/fonts/pdfFonts/Poppins-Regular.ttf";
import MediumPoppins from "../../../../assets/fonts/pdfFonts/Poppins-Medium.ttf";
import SemiBoldPoppins from "../../../../assets/fonts/pdfFonts/Poppins-SemiBold.ttf";

export const PageContainer = ({ children }: { children: ReactNode }) => {
  Font.register({
    family: "Poppins",
    fonts: [
      { src: RegularPoppins, fontWeight: 400 },
      { src: MediumPoppins, fontWeight: 500 },
      { src: SemiBoldPoppins, fontWeight: 600 },
    ],
  });

  return (
    <Document>
      <Page
        size="LETTER"
        style={{
          padding: "24pt",
          fontFamily: "Poppins",
          color: "#323942",
        }}
      >
        {children}
      </Page>
    </Document>
  );
};
