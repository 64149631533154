import { Styles, View } from '@react-pdf/renderer';

import { StyledText } from './StyledText';

export const LabelValueText = ({
  label,
  value,
  style,
}: {
  label: string;
  value: string | null | undefined | number;
  style?: Styles[string];
}) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      ...style,
    }}
  >
    {label && <StyledText content={label} />}
    {value === null ? null : <StyledText content={value || 'N/A'} weight={600} />}
  </View>
);
