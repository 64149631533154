import { Styles, Text } from '@react-pdf/renderer';

type StyledTextProps = {
  content?: string | null | number;
  size?: number;
  weight?: 400 | 500 | 600;
  lineHeight?: number;
  style?: Styles[string];
  debug?: boolean;
};

export const StyledText = ({ content, size = 12, weight = 500, lineHeight = 1.5, style, debug }: StyledTextProps) => (
  <Text
    style={{
      fontSize: `${size}pt`,
      fontWeight: weight,
      lineHeight,
      letterSpacing: '0.2pt',
      ...style,
    }}
    debug={debug}
  >
    {content ?? '-'}
  </Text>
);
