import { Styles, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';

export const Row = ({ children, debug, style }: { children: ReactNode; debug?: boolean; style?: Styles[string] }) => (
  <View
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      ...style,
    }}
    debug={debug}
  >
    {children}
  </View>
);
