import { Styles, View } from '@react-pdf/renderer';
import { ReactNode } from 'react';

export const Col = ({ children, style, debug }: { children: ReactNode; style?: Styles[string]; debug?: boolean }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'column',
      ...style,
    }}
    debug={debug}
  >
    {children}
  </View>
);
